<template>
  <section class="details-wrap" v-loading="loading">
    <!-- 手机顶部样式 start -->
    <div class="top">
      <div class="top-first">
        <div class="time">{{ time }}</div>
        <div class="lump"></div>
        <div class="cell">
          <div class="cell-num">
            {{ cell }}
          </div>
          <div class="cell-icon">
            <div class="inner"></div>
          </div>
        </div>
      </div>
      <div class="top-second">商品详情</div>
    </div>
    <!-- 手机顶部样式 end -->

    <div class="body">
      <!-- banner区域 start -->
      <div class="header">
        <el-carousel trigger="click" height="200px">
          <el-carousel-item
            v-for="(item, index) in bannerList"
            :key="item.type + index"
          >
            <video
              v-if="item.type === 'video'"
              class="video-wrap"
              :src="item.value"
              enable-danmu
              danmu-btn
              controls
              @click="onHandlePreview(item, index)"
            ></video>
            <el-image
              class="image-wrap"
              v-else
              :src="item.value"
              mode="aspectFill"
              @click="onHandlePreview(item, index)"
            ></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- banner区域 end -->

      <!-- 商品详情内容 start -->
      <div class="content">
        <div class="content-first">
          <div class="name">
            <span class="name-title">
              {{ `${details.title}(${details.unit_type})` }} </span
            ><span>{{ `[库存：${details.stock}]` }}</span
            ><span>{{ `[起订：${details.min_num}]` }}</span
            ><span v-if="details.restrict">{{
              `[限购：${details.restrict}]`
            }}</span>
          </div>
          <div class="pice">
            <span>开市价：</span>
            <span class="pice-num">
              {{ `￥${details.price}/${details.unit_type}` }}</span
            >
          </div>
          <div class="unit">
            <div v-for="item of angleList" :key="item.value" class="unit-child">
              <div class="label">
                {{ item.label }}
              </div>
              <div class="value">
                <span>约</span
                ><span style="font-weight: 600">{{ details[item.value] }}</span
                ><span>{{ item.suffix }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content-second">
          <!-- 商品详情 start -->
          <div class="second-title">商品详情</div>
          <div class="content-details-wrap">
            <div
              class="details-unit"
              v-for="(item, index) of detailsList"
              :key="'unit' + index"
            >
              <div class="unit-label">{{ `[${item.label}]：` }}</div>
              <div class="unit-value">{{ item.value }}</div>
            </div>
          </div>
          <!-- 商品详情 end -->

          <!-- 售后标准 start -->
          <div class="second-title">售后标准</div>
          <div class="rules">{{ rules }}</div>
          <div class="rules-sub">{{ rulesSub }}</div>
          <!-- 售后标准 end -->
        </div>
      </div>
      <!-- 商品详情内容 end -->
    </div>

    <!-- 文件预览 start -->
    <c-preview-file
      v-model="dialogVisible"
      :fileList="fileList"
      :fileIndex="previewIndex"
      :fileType="fileType"
    ></c-preview-file>
    <!-- 文件预览 end -->
  </section>
</template>

<script>
import CPreviewFile from "@/components/common/CPreviewFile/index.vue";
import { formatDate } from "@/utils//tools/date.js";
import { IS_AFTER_SALE } from "@/views/general/utils/enum/index";

export default {
  name: "GoodsDetails",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    CPreviewFile,
  },
  data() {
    return {
      time: formatDate(new Date(), "hh:mm"), // 时间
      cell: "100%", // 电池电量
      rules: "虚假、恶意售后、陈货换新将永久关停账户，并赔偿平台相关损失。",
      rulesSub: "出现未在售后赔付范围内争议，以平台最终判定为准。",
      dialogVisible: false, // 图片预览是否开启
      previewIndex: 0, // 下标
      fileType: null, // 当前文件后缀
    };
  },
  computed: {
    /**
     * 获取文件类型
     */
    getFileType() {
      return (file) => {
        const extIndex = file.value.lastIndexOf(".");
        const fileType = file.value.slice(extIndex + 1).toLocaleLowerCase();
        return fileType;
      };
    },
    /**
     * 滚动数据列(视频 && 图片)
     */
    bannerList() {
      const list = [];
      const { srp_video, kv, srp_pic } = this.details;
      srp_video &&
        list.push({
          label: "视频",
          value: srp_video,
          type: "video",
        });
      const kvs =
        (kv && [
          {
            label: "图片",
            value: kv,
            type: "pic",
          },
        ]) ||
        [];
      const picList =
        (srp_pic &&
          srp_pic.split(",").map((item) => {
            return {
              label: "图片",
              value: item,
              type: "pic",
            };
          })) ||
        [];
      const lastList = list.concat(kvs).concat(picList);
      return lastList;
    },
    /**
     * 预览的文件列
     */
    fileList() {
      let list = [];
      list = this.bannerList.map((item) => {
        return {
          name: item.label,
          url: item.value,
        };
      });
      return list;
    },
    /**
     * 商品维度数据
     */
    angleList() {
      const list = [
        {
          label: "毛重",
          value: "gross_weight",
          suffix: "斤",
        },
        {
          label: "毛单价",
          value: "gross_weight_price",
          suffix: "元/斤",
        },
        {
          label: "净重",
          value: "weight",
          suffix: "斤",
        },
        {
          label: "净单价",
          value: "weight_price",
          suffix: "元/斤",
        },
      ];
      return list;
    },
    /**
     * 详情列表
     */
    detailsList() {
      const list = [];
      const {
        origin_name,
        grade,
        gross_weight,
        weight,
        after_sale,
        spec,
        fcst,
        min_se,
        max_se,
        special_label,
        reject,
        ded,
        as_cond,
        intro,
      } = this.details;
      origin_name &&
        list.push({
          label: "产地",
          value: origin_name,
        });
      grade &&
        list.push({
          label: "等级",
          value: grade,
        });
      gross_weight &&
        list.push({
          label: "毛重",
          value: `约 ${gross_weight}斤`,
        });
      weight &&
        list.push({
          label: "净重",
          value: `约 ${weight}斤`,
        });
      list.push({
        label: "是否有售后",
        value: Object.values(IS_AFTER_SALE).find(
          (item) => item.value === after_sale
        )?.label,
      });
      spec &&
        list.push({
          label: "规格",
          value: spec,
        });
      fcst &&
        list.push({
          label: "明日预测",
          value: fcst,
        });
      min_se &&
        list.push({
          label: "最小甜度",
          value: min_se,
        });
      max_se &&
        list.push({
          label: "最大甜度",
          value: max_se,
        });
      special_label &&
        list.push({
          label: "是否特价",
          value: special_label,
        });
      reject &&
        list.push({
          label: "不良率",
          value: reject + "%",
        });
      ded &&
        list.push({
          label: "免赔情况",
          value: ded,
        });
      as_cond &&
        list.push({
          label: "可申请售后条件",
          value: as_cond,
        });
      intro &&
        list.push({
          label: "简介",
          value: intro,
        });
      return list;
    },
  },
  methods: {
    /**
     * 预览文件
     */
    onHandlePreview(file, index) {
      console.log("onHandlePreview 5", file, index);
      this.previewIndex = index;
      this.dialogVisible = true;
      this.fileType = this.getFileType(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.details-wrap {
  width: 100%;
  height: calc(100% - 50px);
  margin: 0 auto;
  margin-bottom: 20px;
  border: 3.5px solid rgb(63, 63, 63);
  box-sizing: border-box;
  border-radius: 25px;
  box-shadow: 5px 8px 8px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  /** 模拟手机顶部样式 */
  .top {
    height: 65px;
    background-color: rgba(0, 0, 0, 0.05);
    &-first {
      height: 40px;
      display: flex;
      justify-content: center;
      .time {
        width: 22%;
        line-height: 45px;
        transform: scale(0.6);
        font-size: 12px;
      }
      .lump {
        flex: 1;
        height: 30px;
        border-bottom-right-radius: 18px;
        border-bottom-left-radius: 18px;
        background-color: #000;
      }
      .cell {
        position: relative;
        width: 22%;
        display: flex;
        line-height: 45px;
        &-num {
          position: absolute;
          left: -5px;
          width: 45px;
          transform: scale(0.6);
          font-size: 12px;
        }
        &-icon {
          position: absolute;
          bottom: 14px;
          left: 26px;
          width: 15px;
          height: 7px;
          padding: 0.5px;
          border: 0.5px solid #000;
          box-sizing: border-box;
          border-radius: 1.5px;
          .inner {
            position: relative;
            top: 0.5px;
            left: 0.8px;
            width: 10px;
            height: 3px;
            background-color: #000;
          }
          &:after {
            content: "";
            position: absolute;
            top: 1.5px;
            right: -3px;
            width: 2.5px;
            height: 2.5px;
            background-color: #000;
          }
        }
      }
    }
    &-second {
      font-size: 12px;
      font-weight: 550;
      text-align: center;
    }
  }
  .body {
    height: 90%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .header {
      height: 150px;
      background-color: #000;
      overflow: hidden;
    }
    .content {
      width: 100%;
      &-first {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        box-sizing: border-box;
        background-color: #fff;
        .name {
          span {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.4);
          }
          .name-title {
            color: rgba(0, 0, 0, 0.7);
          }
        }
        .pice {
          margin: 10px 0;
          font-size: 15px;
          &-num {
            font-size: 16px;
            color: $danger-color;
          }
        }
        .unit {
          display: flex;
          gap: 3px;
          &-child {
            // width: 48px;
            // height: 30px;
            padding: 2px;
            border-radius: 3px;
            background-color: rgba(0, 0, 0, 0.06);
            .label {
              position: relative;
              // top: -3px;
              // left: -7px;
              // transform: scale(0.7);
              margin-bottom: 10px;
              font-size: 14px;
            }
            .value {
              position: relative;
              // top: -15px;
              // left: -9px;
              // transform: scale(0.5);
              font-size: 12px;
              // white-space: nowrap;
            }
          }
        }
      }
      &-second {
        position: relative;
        padding-bottom: 20px;
        &:after {
          content: "";
          position: absolute;
          top: -8px;
          width: 100%;
          height: 5px;
          background-color: rgba(0, 0, 0, 0.05);
        }
        .second-title {
          text-align: center;
          font-size: 14px;
        }
        .content-details-wrap {
          padding: 15px 0;
          .details-unit {
            position: relative;
            left: -10px;
            transform: scale(0.8);
            margin: 10px 0;
            display: flex;
            font-size: 12px;
            .unit-label {
              height: 20px;
              line-height: 20px;
              white-space: nowrap;
              color: rgba(0, 0, 0, 0.3);
            }
            .unit-value {
              // height: 20px;
              line-height: 21px;
            }
          }
        }
        .rules {
          transform: scale(0.9);
          padding: 3px;
          margin: 5px 0;
          font-size: 12px;
          border-radius: 3px;
          color: $warn-color;
          background-color: #fdf4ec;
        }
        .rules-sub {
          transform: scale(0.9);
          font-size: 12px;
        }
      }
    }
  }
}
/deep/ .el-carousel {
  &__arrow {
    display: none;
  }
  &__indicator--horizontal {
    padding: 5px 2px;
  }
  &__indicators--horizontal {
    bottom: 5px;
  }
  &__button {
    width: 10px;
    height: 1.5px;
  }
  .video-wrap {
    width: 100%;
    height: 150px;
    cursor: pointer;
  }
  .el-image {
    width: 100%;
    height: 150px;
    cursor: pointer;
  }
}
</style>
