var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "details-wrap",
    },
    [
      _c("div", { staticClass: "top" }, [
        _c("div", { staticClass: "top-first" }, [
          _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.time))]),
          _c("div", { staticClass: "lump" }),
          _c("div", { staticClass: "cell" }, [
            _c("div", { staticClass: "cell-num" }, [
              _vm._v(" " + _vm._s(_vm.cell) + " "),
            ]),
            _vm._m(0),
          ]),
        ]),
        _c("div", { staticClass: "top-second" }, [_vm._v("商品详情")]),
      ]),
      _c("div", { staticClass: "body" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c(
              "el-carousel",
              { attrs: { trigger: "click", height: "200px" } },
              _vm._l(_vm.bannerList, function (item, index) {
                return _c(
                  "el-carousel-item",
                  { key: item.type + index },
                  [
                    item.type === "video"
                      ? _c("video", {
                          staticClass: "video-wrap",
                          attrs: {
                            src: item.value,
                            "enable-danmu": "",
                            "danmu-btn": "",
                            controls: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onHandlePreview(item, index)
                            },
                          },
                        })
                      : _c("el-image", {
                          staticClass: "image-wrap",
                          attrs: { src: item.value, mode: "aspectFill" },
                          on: {
                            click: function ($event) {
                              return _vm.onHandlePreview(item, index)
                            },
                          },
                        }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "content" }, [
          _c("div", { staticClass: "content-first" }, [
            _c("div", { staticClass: "name" }, [
              _c("span", { staticClass: "name-title" }, [
                _vm._v(
                  " " +
                    _vm._s(`${_vm.details.title}(${_vm.details.unit_type})`) +
                    " "
                ),
              ]),
              _c("span", [_vm._v(_vm._s(`[库存：${_vm.details.stock}]`))]),
              _c("span", [_vm._v(_vm._s(`[起订：${_vm.details.min_num}]`))]),
              _vm.details.restrict
                ? _c("span", [
                    _vm._v(_vm._s(`[限购：${_vm.details.restrict}]`)),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "pice" }, [
              _c("span", [_vm._v("开市价：")]),
              _c("span", { staticClass: "pice-num" }, [
                _vm._v(
                  " " +
                    _vm._s(`￥${_vm.details.price}/${_vm.details.unit_type}`)
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "unit" },
              _vm._l(_vm.angleList, function (item) {
                return _c(
                  "div",
                  { key: item.value, staticClass: "unit-child" },
                  [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(" " + _vm._s(item.label) + " "),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _c("span", [_vm._v("约")]),
                      _c("span", { staticStyle: { "font-weight": "600" } }, [
                        _vm._v(_vm._s(_vm.details[item.value])),
                      ]),
                      _c("span", [_vm._v(_vm._s(item.suffix))]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "content-second" }, [
            _c("div", { staticClass: "second-title" }, [_vm._v("商品详情")]),
            _c(
              "div",
              { staticClass: "content-details-wrap" },
              _vm._l(_vm.detailsList, function (item, index) {
                return _c(
                  "div",
                  { key: "unit" + index, staticClass: "details-unit" },
                  [
                    _c("div", { staticClass: "unit-label" }, [
                      _vm._v(_vm._s(`[${item.label}]：`)),
                    ]),
                    _c("div", { staticClass: "unit-value" }, [
                      _vm._v(_vm._s(item.value)),
                    ]),
                  ]
                )
              }),
              0
            ),
            _c("div", { staticClass: "second-title" }, [_vm._v("售后标准")]),
            _c("div", { staticClass: "rules" }, [_vm._v(_vm._s(_vm.rules))]),
            _c("div", { staticClass: "rules-sub" }, [
              _vm._v(_vm._s(_vm.rulesSub)),
            ]),
          ]),
        ]),
      ]),
      _c("c-preview-file", {
        attrs: {
          fileList: _vm.fileList,
          fileIndex: _vm.previewIndex,
          fileType: _vm.fileType,
        },
        model: {
          value: _vm.dialogVisible,
          callback: function ($$v) {
            _vm.dialogVisible = $$v
          },
          expression: "dialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cell-icon" }, [
      _c("div", { staticClass: "inner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }